import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@square-enix-private/vaquita-ui';
import { Button } from '~/components/Buttons';
import { getEtailers } from '../utils';

const ChooseFormat = ({ onClick, edition, locale }) => {
  const [formats, setFormats] = useState([]);
  const cmsFormats = useTranslation('buyformats').t('content', {
    returnObjects: true
  });

  useEffect(() => {
    const fetchFormats = async () => {
      const rawEtailers = await getEtailers({
        edition: edition.toLowerCase(),
        country: locale
      });

      const filteredFormats = cmsFormats.filter(({ key }) => rawEtailers.find(({ format }) => format === key)
      );

      setFormats(filteredFormats);
    };

    fetchFormats();
  }, [edition, locale]);

  return formats.map(({ key, label }) => (
    <Button
      variant="secondary"
      key={key}
      onClick={() => onClick(key)}
      p="medium"
      m="small"
      maxWidth={['calc(100% - 16px)', 400]}
      height={140}
    >
      <Box my={['small', null, 'large']} fontSize="xlarge">
        {label}
      </Box>
    </Button>
  ));
};

ChooseFormat.propTypes = {
  onClick: PropTypes.func,
  edition: PropTypes.string,
  format: PropTypes.string,
  platform: PropTypes.string,
  locale: PropTypes.string
};

ChooseFormat.defaultProps = {
  onClick: null,
  edition: null,
  format: null,
  platform: null,
  locale: null
};

export default ChooseFormat;
