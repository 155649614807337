import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@square-enix-private/vaquita-ui';
import { getPlatforms } from '../utils';
import { Button } from '~/components/Buttons';
import { LogoSVG } from '~/components/HeroSection/elements/SVGImage';
import buttonColors from '~/constants/buttonColors';

const normalisePlatforms = (platforms) => platforms.map((platform) => {
  if (platform.toLowerCase() === 'pc') {
    return 'steam';
  }

  return platform.toLowerCase();
});

const ChoosePlatforms = ({ onClick, edition, format }) => {
  const { t } = useTranslation('buyplatforms');
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    const platformsData = t('platforms', { returnObjects: true });
    const fetchPlatforms = async () => {
      const platformsByEdition = await getPlatforms({
        edition,
        format
      });
      const normalisedPlatforms = normalisePlatforms(platformsByEdition);
      const filteredPlatforms = platformsData.filter(({ title }) => normalisedPlatforms.includes(title.toLowerCase())
      );

      setPlatforms(filteredPlatforms);
    };

    fetchPlatforms();
  }, [edition, format]);

  return (
    <Flex width="100%" flexWrap={['wrap', 'unset']} justifyContent="center">
      {platforms.map(
        ({
          id,
          image,
          title,
          fill,
          orientation,
          displayWidth,
          displayHeight
        }) => {
          const data = buttonColors[title]
            ? buttonColors[title]
            : buttonColors.default;
          const { hoverFill } = data;

          const slug = title.toLowerCase() === 'steam' ? 'pc' : title;

          return (
            <Button
              key={id}
              onClick={() => onClick(slug)}
              p="medium"
              m="small"
              width="100%"
              hoverFill={hoverFill}
              bgColor={hoverFill}
              maxWidth={['calc(100% - 16px)', 400]}
              height={140}
            >
              <Box my={['small', null, 'medium']}>
                <LogoSVG
                  src={image.src}
                  alt={title}
                  fill={fill}
                  orientation={orientation}
                  displayWidth={displayWidth}
                  displayHeight={displayHeight}
                />
              </Box>
            </Button>
          );
        }
      )}
    </Flex>
  );
};

ChoosePlatforms.propTypes = {
  onClick: PropTypes.func,
  edition: PropTypes.string,
  format: PropTypes.string
};

ChoosePlatforms.defaultProps = {
  onClick: null,
  edition: null,
  format: null
};

export default ChoosePlatforms;
