import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { Box } from '@square-enix-private/vaquita-ui';
import { Button } from '~/components/Buttons';

const ChooseEdition = ({ onClick }) => {
  const entries = useTranslation('buyeditions').t('content', {
    returnObjects: true
  });

  return entries.map(({ slug, label, logo, etailersKeys, background }) => (
    <Button
      variant="secondary"
      key={slug}
      onClick={() => onClick({ label, key: etailersKeys }, background)}
      p="medium"
      m="small"
      maxWidth="calc(100% - 16px)"
      height={140}
    >
      <Box maxWidth={260} m="auto">
        <Img fluid={logo.image} alt={logo.alt} />
      </Box>
    </Button>
  ));
};

ChooseEdition.propTypes = {
  onItemClick: PropTypes.func
};

ChooseEdition.defaultProps = {
  onItemClick: null
};

export default ChooseEdition;
