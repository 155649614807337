import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  EDITION,
  FORMAT,
  PLATFORM,
  ETAILERS,
  LIST_OF_STEPS
} from './constants';

import ChooseEdition from './ChooseEdition';
import ChooseFormat from './ChooseFormat';
import ChoosePlatforms from './ChoosePlatforms';
import ChooseEtailers from './ChooseEtailers';
import { getCountries } from '../utils';

async function geoCheck() {
  const res = await fetch(process.env.GATSBY_GEO_IP_ENDPOINT);

  const text = await res.text();

  const location = text.replace(/"/g, '');

  return location;
}

const Steps = ({
  game,
  index,
  setGame,
  setBackground,
  setStep,
  locale: currentLocale
}) => {
  const [geoLocation, setGeoLocation] = useState(currentLocale);

  let View = (
    <ChooseEdition
      onClick={({ key, label }) => {
        setGame({ ...game, edition: key, label });
        setStep(index + 1);
      }}
    />
  );

  const type = LIST_OF_STEPS[index];

  const fetchGeoLocation = async () => {
    const countries = await getCountries();
    const location = await geoCheck();

    if (countries.includes(location)) {
      setGeoLocation(location);
    }
  };

  useEffect(() => {
    fetchGeoLocation();
  }, []);

  switch (type) {
    case EDITION: {
      View = (
        <ChooseEdition
          onClick={({ key, label }, background) => {
            setGame({ ...game, edition: key, label });
            setStep(index + 1);
            setBackground(background);
          }}
        />
      );

      break;
    }
    case FORMAT: {
      View = (
        <ChooseFormat
          onClick={(format) => {
            setGame({ ...game, format });
            setStep(index + 1);
          }}
          locale={geoLocation}
          {...game}
        />
      );

      break;
    }

    case PLATFORM: {
      View = (
        <ChoosePlatforms
          onClick={(platform) => {
            setGame({ ...game, platform: platform.toLowerCase() });
            setStep(index + 1);
          }}
          locale={geoLocation}
          {...game}
        />
      );

      break;
    }

    case ETAILERS: {
      View = <ChooseEtailers locale={geoLocation} {...game} />;
      break;
    }

    default:
      break;
  }

  return View;
};

Steps.propTypes = {
  game: PropTypes.object,
  index: PropTypes.number,
  setGame: PropTypes.func,
  setStep: PropTypes.func,
  locale: PropTypes.string
};

Steps.defaultProps = {
  game: null,
  index: 0,
  setGame: null,
  setStep: null,
  locale: ''
};

export default Steps;
