export const GAME_PROPS = {
  edition: null,
  format: null,
  platform: null
};

export const EDITION = 'edition';
export const FORMAT = 'format';
export const PLATFORM = 'platform';
export const ETAILERS = 'etailers';

export const LIST_OF_STEPS = [EDITION, FORMAT, PLATFORM, ETAILERS];
