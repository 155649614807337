import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@square-enix-private/vaquita-ui';
import ExternalLink from '~/components/Buttons/ExternalLink';

import { getEtailers } from '../utils';

const StyleBox = styled(Flex)`
  background: #fff;
`;

const StyleImg = styled('img')`
  width: 100%;
`;

const ChooseEtailers = ({ edition, format, platform, locale }) => {
  const [etailers, setEtailers] = useState([]);

  useEffect(() => {
    const fetchEtailers = async () => {
      const rawEtailers = await getEtailers({
        edition: edition.toLowerCase(),
        format,
        platform: platform.toLowerCase(),
        country: locale,
        strict: true
      });

      setEtailers(rawEtailers);
    };

    fetchEtailers();
  }, [edition, format, platform, locale]);

  return (
    <Flex flexWrap="wrap" alignItems="center" justifyContent="space-evenly">
      {etailers.map(({ link, logo, store }) => (
        <StyleBox
          justifyContent="center"
          alignItems="center"
          width={[160]}
          height={[78]}
          m={['small']}
          key={link}
        >
          <ExternalLink href={link}>
            <StyleImg src={logo} alt={store} data-prop={logo} />
          </ExternalLink>
        </StyleBox>
      ))}
    </Flex>
  );
};

ChooseEtailers.propTypes = {
  edition: PropTypes.string,
  format: PropTypes.string,
  platform: PropTypes.string,
  locale: PropTypes.string
};

ChooseEtailers.defaultProps = {
  edition: null,
  format: null,
  platform: null,
  locale: null
};

export default ChooseEtailers;
