import styled from '@emotion/styled';
import { Box, Flex } from '@square-enix-private/vaquita-ui';
import GatsbyLink from 'gatsby-link';
import { color, maxHeight, space } from 'styled-system';
import BgTexture from '~/assets/pages-background.jpg';

import { SEHeaderHeight } from '~/constants/site';

export const Link = styled(GatsbyLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  ${color};

  &:hover {
    text-decoration: underline;
  }
`;

export const BuyWrapper = styled(Box)`
  width: 100%;
  min-height: calc(100vh - ${SEHeaderHeight}px);
  position: relative;
  overflow: hidden;
  background-image: url(${BgTexture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ProductSelectWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0 0 auto;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
`;

export const DeluxeEditionWrapper = styled(Flex)`
  flex: 0 0 auto;
  max-width: 1200px;
  width: 100%;
  position: relative;
`;

export const DeluxeEditionTextWrapper = styled(Box)`
  text-align: center;
`;

export const HTMLBox = styled(Box)`
  h3 {
    margin-top: 0;
    margin-bottom: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-position: inside;
    margin-bottom: 16px;
  }
`;

export const ExtendedBox = styled(Box)`
  ${maxHeight};
  ${space};
`;
