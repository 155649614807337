import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '~/components/SectionTitle/elements';

import BackButton from './BackButton';
import Steps from './Steps';
import useSteps from './stepsHook';

const ProductSteps = ({ locale, changeBackground }) => {
  const { t } = useTranslation('assets');
  const assets = t('UI', { returnObjects: true });
  const editions = useTranslation('buyeditions').t('content', {
    returnObjects: true
  });

  const {
    title,
    showBack,
    game,
    step,
    setGame,
    setStep,
    goPreviousStep
  } = useSteps();

  const { edition, label, ...rest } = game;
  const gameByLabel = { edition: label, ...rest };

  const breadcrumb = Object.values(gameByLabel).filter((value) => Boolean(value)
  );

  useEffect(() => {
    if (!step) {
      changeBackground();
    }
  }, [step]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameSlug = urlParams.get('game');

    const edition = editions.find((edition) => edition.slug === gameSlug);

    if (edition) {
      setGame({ ...game, edition: edition.etailersKeys, label: edition.label });
      setStep(1);
      changeBackground(edition.background);
    }
  }, []);

  return (
    <Box width={1}>
      <Flex flexDirection="column" mb="large">
        <Flex width="100%" justifyContent="center" alignItems="center">
          <SectionTitle
            fontSize={[56]}
            textAlign="center"
            my="medium"
            upperCase
            titleTexture={assets.titleTexture.childImageSharp.fluid.src}
          >
            {title}
          </SectionTitle>
        </Flex>
        <Flex
          width="100%"
          mb="medium"
          justifyContent="center"
          alignItems="center"
          minHeight={40}
          style={{
            filter: 'drop-shadow(2px 2px 1px rgb(0,0,0,0.6))',
            textAlign: 'center'
          }}
        >
          <Text upperCase fontSize={22}>
            {breadcrumb.join(' / ')}
          </Text>
        </Flex>
        <Flex
          flexDirection={['column']}
          alignItems="center"
          position="relative"
          mb={['medium', null, 'large']}
        >
          <Flex flexDirection="row" width="100%" m="small">
            {showBack && <BackButton onClick={goPreviousStep} />}
          </Flex>

          <Flex
            flexDirection={['column', 'row']}
            width={['100%']}
            justifyContent="center"
            minHeight={[60, 140]}
          >
            <Steps
              index={step}
              game={game}
              setGame={setGame}
              setStep={setStep}
              locale={locale}
              setBackground={changeBackground}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

ProductSteps.propTypes = {
  locale: PropTypes.string,
  changeBackground: PropTypes.func
};

ProductSteps.defaultProps = {
  locale: '',
  changeBackground: null
};

export default ProductSteps;
