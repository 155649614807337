import styled from '@emotion/styled';
import { Heading as BaseHeading } from '@square-enix-private/vaquita-ui';

const Heading = styled(BaseHeading)`
  text-transform: uppercase;
  text-shadow: 2px 2px 3px #000;
  text-align: center;
  margin: 0;
`;

export default Heading;
