import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box } from '@square-enix-private/vaquita-ui';
import { Button } from '~/components/Buttons';

const StyledButton = styled(Button)`
  background-image: url(${({ icon }) => icon});
  background-position: 10px;
  filter: drop-shadow(2px 4px 6px black);
  font-size: 22px;

  & span {
    margin-left: 15px;
  }

  :hover {
    @supports (-ms-scroll-limit: 0) {
      background-image: url(${({ iconRev }) => iconRev});
    }
    transform: scale(1.4);
  }

  ::after {
    @supports (clip-path: circle(100% at 50% 50%)) {
      background-image: url(${({ iconRev }) => iconRev});
      background-position: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

const BackButton = ({ onClick }) => {
  const { t } = useTranslation('assets');
  const buttons = t('UI', { returnObjects: true });
  const back = useTranslation('preorderpage').t('goBack');

  return (
    <Box>
      <StyledButton
        onClick={onClick}
        icon={buttons.backButton.publicURL}
        iconRev={buttons.backButton.publicURL}
        bgHover
      >
        {back}
      </StyledButton>
    </Box>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  onClick: null
};

export default BackButton;
