import { useTranslation } from 'react-i18next';

export const useGalleryData = () => {
  const { t, i18n } = useTranslation('gallery');
  const items = i18n.exists('gallery:items')
    ? t('items', { returnObjects: true })
    : undefined;

  return {
    items
  };
};

export const useHighlightsData = () => {
  const { t, i18n } = useTranslation('highlights');
  const items = i18n.exists('highlights:mediaItems')
    ? t('mediaItems', { returnObjects: true })
    : undefined;

  return {
    items
  };
};

export const useBuyPageData = () => {
  const { t } = useTranslation('etailersList');
  const storesData = t('storesData', { returnObjects: true });

  return {
    storesData
  };
};
