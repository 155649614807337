import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@square-enix-private/vaquita-ui';
import ReactSVG from 'react-svg';

const portrait = 'portrait';

const StyledSVG = styled(ReactSVG)`
  height: inherit;
  width: inherit;
  & div {
    height: inherit;
    width: inherit;
    display: flex;
  }
  & svg {
    fill: ${({ fill }) => fill};
    width: inherit;
    height: inherit;
  }
`;

export const LogoSVG = ({
  src,
  fill,
  orientation,
  displayWidth,
  displayHeight
}) => {
  const height = orientation === portrait ? displayHeight : 'auto';
  const width = orientation === portrait ? 'auto' : displayWidth;

  return (
    <Flex width={width} height={height} justifyContent="center" m="auto">
      <StyledSVG width={width} height={height} src={src} fill={fill} />
    </Flex>
  );
};

LogoSVG.propTypes = {
  src: PropTypes.string.isRequired,
  fill: PropTypes.string,
  orientation: PropTypes.string,
  displayWidth: PropTypes.array,
  displayHeight: PropTypes.array
};

LogoSVG.defaultProps = {
  fill: null,
  orientation: 'landscape',
  displayWidth: [130, 120],
  displayHeight: [40, 50]
};

export default LogoSVG;
