const localeToCountryCodeMap = {
  de: 'DE',
  es: 'ES',
  fr: 'FR',
  'en-gb': 'GB',
  'en-us': 'US',
  it: 'IT',
  pl: 'PL',
  'pt-br': 'BR',
  'es-mx': 'MX',
  nl: 'NL'
};

export default localeToCountryCodeMap;
