import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GAME_PROPS,
  EDITION,
  FORMAT,
  PLATFORM,
  ETAILERS,
  LIST_OF_STEPS
} from './Steps/constants';

const useSteps = () => {
  const [step, setStep] = useState(0);
  const [game, setGame] = useState(GAME_PROPS);
  const { t } = useTranslation('preorderpage');
  const [title, setTitle] = useState(t('editionSelect'));

  const goPreviousStep = () => {
    const propID = LIST_OF_STEPS[step - 1];

    setGame({ ...game, [propID]: null });
    setStep(step - 1);
  };

  useEffect(() => {
    const type = LIST_OF_STEPS[step];

    switch (type) {
      case EDITION: {
        setTitle(t('editionSelect'));
        break;
      }
      case FORMAT: {
        setTitle(t('formatSelect'));

        break;
      }
      case PLATFORM: {
        setTitle(t('platformSelect'));
        break;
      }

      case ETAILERS: {
        setTitle(t('retailerSelect'));
        break;
      }

      default:
        setTitle(t('editionSelect'));
        break;
    }
  }, [step]);

  return {
    showBack: step !== 0,
    title,
    step,
    game,
    goPreviousStep,
    setGame,
    setStep
  };
};

export default useSteps;
