import psBackground from '~/assets/psBackground.png';
import xboxBackground from '~/assets/xboxBackground.png';
import steamBackground from '~/assets/steamBackground.png';

const buttonColors = {
  PS4: {
    image: psBackground,
    hoverFill: '#006fcd'
  },
  PS5: {
    image: psBackground,
    hoverFill: '#006fcd'
  },
  'XBox One': {
    image: xboxBackground,
    hoverFill: '#0e7a0d'
  },
  'XBox Series X | S': {
    image: xboxBackground,
    hoverFill: '#0e7a0d'
  },
  default: {
    image: steamBackground,
    hoverFill: '#585858'
  }
};

export default buttonColors;
