import { Flex, Box } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import {
  backgroundPosition,
  position,
  backgroundSize,
  height,
  top,
  left
} from 'styled-system';
import { transform } from '~/custom/styled-system';

export const PageWrap = styled(Flex)`
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 320px;
  position: relative;
`;

export const Background = styled(Box)`
  background-image: url(${({ src }) => src});
  background-size: contain;
  flex: 1 0 auto;
  position: absolute;
  background-repeat: no-repeat;
  ${top};
  ${left};
  ${backgroundPosition};
  ${backgroundSize};
  ${transform}
`;

export const Wrapper = styled(Flex)`
  ${height};
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${position};
  ${top};
`;
