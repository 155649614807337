import localeToCountryCodeMap from '~/utils/localeToCountryCodeMap';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';

const emptyFunc = () => null;

export const getSearch = (link) => {
  let newLink = link;

  if (typeof global.location !== 'undefined') {
    newLink += global.location.search.replace('?', '&');
  }

  return newLink;
};

export const apiURL = '/api/v1/game/outriders/';

export const sortByCurrentLocale = (arr, locale) => {
  const newArr = arr.filter(
    (country) => country !== localeToCountryCodeMap[locale]
  );

  if (localeToCountryCodeMap[locale]) {
    newArr.unshift(localeToCountryCodeMap[locale]);
  }

  return newArr;
};

export const getCountries = async (locale, setCountries = emptyFunc) => {
  const response = await fetch(`${apiURL}countries`);
  const rawData = await response.json();
  const data = sortByCurrentLocale(rawData, locale);

  setCountries(data);

  return data;
};

export const getPlatforms = async ({
  cb = emptyFunc,
  edition: editionStr = '',
  ...rest
} = {}) => {
  const edition = editionStr.split(',');
  const qs = querystring.stringify(
    { edition, ...rest },
    { arrayFormat: 'comma' }
  );

  const response = await fetch(`${apiURL}platforms?${qs}`);

  const data = await response.json();

  cb({ data, ...rest });

  return data;
};

export const getEditions = async ({ cb = emptyFunc, ...rest } = {}) => {
  const qs = querystring.stringify(rest);
  const response = await fetch(`${apiURL}editions?${qs}`);

  const data = await response.json();

  cb({ ...rest, data });

  return data;
};

export const getEtailers = async ({
  cb = emptyFunc,
  strict = true,
  edition: editionStr,
  ...rest
} = {}) => {
  const edition = editionStr.split(',');
  const qs = querystring.stringify(
    { edition, ...rest, strict },
    { arrayFormat: 'comma' }
  );

  const response = await fetch(`${apiURL}etailers?${qs}`);

  const data = await response.json();

  cb(data);

  return data;
};

export const useEtailersData = (locale) => {
  const { t } = useTranslation('etailers');
  const country = t('country', { returnObjects: true });
  const countries = sortByCurrentLocale(
    t('getCountries', { returnObjects: true }),
    locale
  );

  const platforms = country.platforms.map(({ platformLabel }) => platformLabel);
  const editions = country.platforms[0].editions.map(
    ({ editionsLabel }) => editionsLabel
  );
  const retailers = country?.platforms[0]?.editions[0]?.etailers.map((data) => data) || [];

  return {
    countries,
    platforms,
    editions,
    retailers
  };
};
