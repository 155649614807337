import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { Box, Flex } from '@square-enix-private/vaquita-ui';

import { useBuyPageData } from '~/hooks/translations';
import { PhoneOnly } from '~/components/Buckets';
import { ProductSteps } from '~/components/BuyPage';
import { SEHeaderHeight } from '~/constants/site';
import {
  BuyWrapper,
  ProductSelectWrapper,
  ExtendedBox
} from '../components/BuyPage/elements';

import { Background, Wrapper } from '~/components/IntroSection/elements';

const BuyPage = ({ pageContext: { locale } }) => {
  const { storesData } = useBuyPageData();
  const { t } = useTranslation('preorderpage');
  const poster = t('loopableBackgroundPoster', { returnObjects: true });
  const defaultBgData = {
    image: poster.image,
    alt: poster.alt
  };
  const [bgData, setBkg] = useState(defaultBgData);

  const changeBackground = (data) => {
    if (data) {
      setBkg(data);

      return;
    }

    setBkg(defaultBgData);
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={[50, null, 0]}
      style={{ overflow: 'hidden' }}
      min-height="100vh"
      pt={[0, null, '60px']}
    >
      <GTMData pageId="buy" breadcrumb="buy" />
      <BuyWrapper>
        <Background
          height={['55vh', `calc(100vh - ${SEHeaderHeight}px)`]}
          left="50%"
          width={['200%', null, '100%']}
          transform={['translate(-50%, 0px)', null]}
          overflow="hidden"
        >
          <Img
            fluid={bgData.image}
            alt={bgData.alt}
            style={{ aspectRatio: '16/9' }}
          />
          <PhoneOnly>
            <Wrapper
              position="absolute"
              top={0}
              height={['55vh', '80vh', null]}
            />
          </PhoneOnly>
        </Background>
        <ExtendedBox
          width="100%"
          m="auto"
          style={{ aspectRatio: '16/9' }}
          maxHeight={[null, null, `calc(100vh - ${SEHeaderHeight}px - 60px)`]}
        />
        <ProductSelectWrapper
          px={['medium', 'large']}
          mt={[0, null, -460]}
          mb={[0, null, 50]}
        >
          <Box width={1} maxWidth={1200}>
            <ProductSteps
              storesData={storesData}
              locale={locale}
              changeBackground={changeBackground}
            />
          </Box>
        </ProductSelectWrapper>
      </BuyWrapper>
    </Flex>
  );
};

BuyPage.propTypes = {
  pageContext: PropTypes.object
};

BuyPage.defaultProps = {
  pageContext: {}
};

export const query = graphql`
  query($locale: String!) {
    ...PreorderpageData
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    buyeditions(filter: { localeDirectory: { eq: $locale } }) {
      label
      slug
      etailersKeys
      background {
        image {
          ...FastlyProps
        }
        alt
      }
      logo {
        image {
          ...FastlyProps
        }
        alt
      }
    }
    buyformats(filter: { localeDirectory: { eq: $locale } }) {
      label
      key
    }
    buyplatforms: demoherointro(filter: { localeDirectory: { eq: $locale } }) {
      platforms: demoPlatforms {
        id
        fill
        displayWidth
        displayHeight
        orientation
        title
        image {
          src
        }
      }
    }
    etailers {
      getCountries(id: "OUTRIDERS")
      country(game: "OUTRIDERS", id: $locale) {
        platforms {
          platformLabel
          editions {
            editionsLabel
            etailers {
              link
              country
              edition
              format
              logo
              packshot
              platform
              store
              pid
            }
          }
        }
      }
    }
  }
`;

export default BuyPage;
